body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 10px;
  padding: 5px;
  
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.scroll-to-top-button img{
  height: 20px ;
  width: 20px;
}


.filter{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.textfilter{
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
  color: #103E58;
}

.butpro {
  display: inline-block;
  padding: 20px 50px;
  margin: 5px;
  background-color: #536976;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.butpro:hover {
  background-color: #033064;
}


@media (min-width: 250px) and (max-width: 900px) {
  .filter {
    flex-direction: column;
    /* gap: 10px; */
  }

  .textfilter {
    font-size: 2rem;
  }

  .butpro {
    text-align: center;
    display: flex;
  align-items: center;
  justify-content: center;
    width: 150px;
    font-size: 16px;
  }
  
}



.projects {
  padding: 10px 40px;
  background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
}

.projectSec {
  padding: 10px 10px 30px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
}


.imgp {
  cursor: pointer;
  background-color: white;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.103);
  overflow: hidden;
}

.imgp:hover {
  /* background-color: rgb(216, 0, 0); */
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.429);


}

.imgp .img {
  transition: transform 0.8s ease;
  height: 240px;
  width: 100%;
  overflow: hidden;

}


.imgp .img:hover {
  overflow: hidden;
  transform: scale(1.1);
}

.projectSec .imgp .heading p {
  text-align: center;
  color: black;
}



@media (min-width: 250px) and (max-width: 800px) {
  .projectSec {
    grid-template-columns: repeat(1, 1fr);
  }

  .imgp {
    width: 100%;
  }

  .imgp .img {
    transition: transform 0.8s ease;
    height: auto;
    width: 100%;
    overflow: hidden;
  }

  .projectSec .imgp .heading p {
    text-align: center;
    color: black;
    font-size: 10px;
  }


}

@media (min-width: 800px) and (max-width: 1200px) {
  .imgp .img {
    transition: transform 0.8s ease;
    height: auto;
    width: 100%;
    overflow: hidden;
  }
}


h1 {
  text-align: center;
  width: 100%;
  height: 3rem;
  background-color: rgba(240, 255, 255, 0.201);
  color: #103E58;
  padding: 30px 10px;
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
}

@media (min-width: 250px) and (max-width: 600px) {
  h1 {
    margin: 0;
    text-align: center;
    width: 100%;
    height: 3rem;
    background-color: rgba(240, 255, 255, 0.201);
    color: #103E58;
    padding: 10px 10px;
    font-family: 'Inria Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
  }
}

.aboutProject {
  height: 60vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/liv3.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4 {
  font-size: 7rem;
  color: #e0f6ff;
}

@media (min-width: 250px) and (max-width: 900px) {
  h4 {
    font-size: 3rem;
    color: #e0f6ff;
  }
}


.headSer {
  height: 60vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/liv4.jpeg);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4 {
  font-size: 7rem;
  color: #e0f6ff;
}

@media (min-width: 250px) and (max-width: 900px) {
  h4 {
    font-size: 3rem;
    color: #e0f6ff;
  }
}




.headabout {
  height: 60vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/liv1.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4 {
  font-size: 7rem;
  color: #e0f6ff;
}

@media (min-width: 250px) and (max-width: 900px) {
  h4 {
    font-size: 3rem;
    color: #e0f6ff;
  }
}

/* modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);



}

.modalButton{
  font-size: 1.5rem;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 550px;
  width: 100%;
  overflow: hidden;

}



.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* text in modal */

.aboutPRO{
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  height: 70vh;
  text-align: start;
  margin-left: 60px;
}

.hhad{
  padding-right: 10px;
display: flex;
align-items: start;
justify-items: start;
  margin-bottom: 10px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #000000;
  text-align: start;
  font-weight: 500;
}

.colortextP{
  padding-left: 20px;
  font-size: 1.2rem;
  color: #0054b5;
  font-weight: 400;
}

.headddPRo{
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  
}


/* @media (min-width: 300px) and (max-width: 600px) {
  .aboutPRO {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  .hhad {
    padding-right: 0;
    margin-bottom: 5px;
    font-size: 1rem;
  }

  .colortextP {
    padding-left: 0;
    font-size: 1rem;
  }

  .headddPRo {
    font-size: 1.2rem;
  }
} */


@media (min-width: 250px) and (max-width: 900px) {
  .modal-content {
    height: 90vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .modalButton{
    font-size: .8rem;
    margin-bottom: 10px;
  }

  .aboutPRO {
    width: 100%;
    margin-left: 0;
    padding: 0 0px;
  }

  .hhad {
    padding-right: 0;
    margin-bottom: 5px;
    font-size: 1rem;
  }

  .colortextP {
    padding-left: 0;
    font-size: 1rem;
  }

  .headddPRo {
    font-size: 1.2rem;
  }


  
  
}

/* Navbar Starts */

.Navlogo {
  position: absolute;
  top: 0px;
  left: 20px;
  width: 130px;
  height: 100px;
  margin-left: 20px;
}


.navbar {
  position: fixed;
  /* Change to fixed */
  top: 0;
  /* Place at the top */
  left: 0;
  /* Adjust left position if needed */
  background: linear-gradient(270deg, #717171 0%, rgba(83, 105, 118, 0.5375) 46.25%, rgba(41, 46, 73, 0) 100%);
  height: 70px;
  z-index: 1000;
  /* Increase z-index to make it appear on top */
  width: 100%;
  /* Make the navbar  the full width */
}

.navbar.scrolled {
  background-color: #536976;
  /* Change the background color on scroll */
}

.container {
  position: relative;
}

.menu-btn {
  /* border: 2px solid blueviolet; */
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 6;
}

.menu-btn::before,
.menu-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  transition: transform 0.3s ease;
}

.menu-btn::before {
  top: 0;
  right: 0;
}

.menu-btn::after {
  bottom: 0;
  right: 0;
}

.menu-btn.open::before {
  transform: translateY(12px) rotate(45deg);
}

.menu-btn.open::after {
  transform: translateY(-12px) rotate(-45deg);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.backdrop.open {
  opacity: 1;
  pointer-events: auto;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.087);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: 1px solid rgba(54, 54, 54, 0.122);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 2;
}

.menu.open {
  transform: translateX(0);
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.link {
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
  margin: 10px 0;
  padding: 10px;
}

.link:hover {
  color: #aaf;
}


/* Navbar Ends */


/* Landing Page Starts */

.LandingPage {
  margin: 0;
  padding: 0;
}

/* slider Starts */


.slider-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

}

.slider-container .slide {

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease;
}

.slider-container .slide.active {
  opacity: 1;
}

.slider-container .slide-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 254, 254);
  text-shadow: 2px 2px 4px black;
  font-size: 34px;
  text-transform: uppercase;
  letter-spacing: 15px;
  text-align: center;
  z-index: 2;
}

.slider-container .slider-btn {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.slider-container .smalltext {
  position: absolute;
  top: 80%;
  right: 20px;
  text-shadow: 3px 4px 6px black;
  transform: translateY(-50%);
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  text-transform: uppercase;
  border-radius: 50%;
}

.slider-container .under {
  position: absolute;
  top: 83%;
  right: 20px;
  text-shadow: 2px 2px 4px black;
  transform: translateY(-50%);
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.slider-container .prev-btn {
  right: 60px;
}

.slider-container .next-btn {
  right: 15px;
}

.img-hero {
  width: 100%;
  height: 100vh;

}

.under {
  width: 60px;
  height: 3px;
  background-color: white;
}

@media (min-width:600px) and (max-width:1200px) {
  .img-hero {
    width: auto;
    height: 100vh;
  }
}

@media (min-width:200px) and (max-width:600px) {
  .img-hero {
    width: auto;
    height: 100vh;
  }

  .slider-container .slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    z-index: 2;
  }


  .Navlogo {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100px;
    height: 100px;
    margin-left: 20px;
  }
}

/* slider Ends */




.aboutCompany {
  background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
  width: 100%;
  height: 108vh;

}

.aboutCompany2 {
  /* background-color: rgb(121, 84, 84); */
  /* border: 5px solid black ; */
  padding: 5% 3% 5% 3%;
  height: 85vh;

}

.aboutCompany3 {
  /* background-color: #103E58; */
  text-align: center;
  font-size: 350%;
}

.aboutCompany4 {
  /* background-color: bisque; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;

}

.aboutImage {
  /* background-color: blue; */
  overflow: hidden;
  width: 45vw;
  height: 100%;
  margin-right: 2vw;

}

.aboutDiscription {
  /* background-color: rgb(163, 157, 35); */
  width: 25vw;
  font-size: 150%;
  margin-left: 5vw;
  text-align: justify;
  margin-top: 1vw;
}

.title {
  font-size: 200%;
  margin: 1vw 3vw 0.5vw 1vw;
  font-weight: bold;
}

.highlighter {
  height: 0.2rem;
  width: 7rem;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
  background-color: #103E58;
  /* padding: 0 15rem 0 0.3rem; */
}

.aboutTeam {
  background: linear-gradient(270deg, #BBD2C5 0%, rgba(83, 105, 118, 0.5375) 46.25%, rgba(41, 46, 73, 0) 100%);
  height: 108vh;

}

.aboutTeam2 {
  /* background-color: rgb(121, 84, 84); */
  /* border: 5px solid black ; */
  padding: 5% 3% 5% 3%;
  height: 85vh;

}

.aboutTeam3 {
  /* background-color: #103E58; */
  text-align: center;
  font-size: 350%;
}

.aboutTeam4 {
  /* background-color: bisque; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vw;

}

.aboutTeamImage {
  /* background-color: blue; */
  overflow: hidden;
  width: 32vw;
  margin-left: 2vw;

}

.aboutTeamDiscription {
  /* background-color: rgb(163, 157, 35); */
  width: 30vw;
  font-size: 150%;
  margin-right: 1vw;
  text-align: justify;
  margin-top: 1vw;
}

.Teamtitle {
  font-size: 200%;
  margin: 2vw 2vw 0.5vw 0;
  font-weight: bold;
}

.Teamhighlighter {
  height: 0.2rem;
  width: 7rem;
  /* margin-left: 0.8rem; */
  margin-top: 0.8rem;
  background-color: #103E58;
  /* padding: 0 15rem 0 0.3rem; */
}

/* start */

.ab {
  background-image: url(./img/abee.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  /* background-position: bottom bottom; */

}

.about {

  background: linear-gradient(270deg, #BBD2C5 0%, rgba(83, 105, 118, 0.5375) 46.25%, rgba(41, 46, 73, 0) 100%);

}


.image-description {
  height: 100vh;
  display: flex;
  align-items: center;
}

.image-container {
  flex: 1;
  margin-right: 20px;
}

.image-container img {
  padding: 20px 0px 0 90px;
  width: 100%;
  height: 100%;
}

.description-container {
  font-size: 1.5rem;
  flex: 1;
}

.description-container p {
  padding: 30px 100px;
  margin-left: 90px;
  /* font-size: 18px; */
  /* font-weight: 500; */
  line-height: 1.5;
  color: rgb(0, 0, 0);
  /* background-color: #e0f6ff4e; */
  /* border-radius: 10%; */
  /* z-index: -2; */
}

.head {
  font-size: 3rem;
  text-align: center;
  padding-top: 50px;
  letter-spacing: 3px;
  font-weight: 700;
  color: #103E58;
}

/* -------------------------------- */
.yard-heading {
  font-size: xx-large;
}

.under-yard {
  margin-top: -10px;
  margin-bottom: 20px;
  width: 80px;
  height: 4px;
  background-color: #103E58;
}

@media screen and (min-width: 250px) and (max-width: 600px) {

  .about {
    height: auto;
  }

  .image-description {
    flex-direction: column-reverse;
    height: auto;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .description-container {
    padding: 20px 40px;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .image-container {
    flex: 1;
    /* margin-right: 20px; */
    padding: 10px 10px;

  }

  .image-container img {
    /* padding: 20px 10px 0 10px; */
    padding: 0;
    height: auto;
    width: 100%;


  }

  .description-container p {
    margin: 0;
    padding: 20px 40px;
    text-align: center;
    font-size: 1.5rem;
  }

  .yard-heading {
    font-size: 1.5rem;
  }

  .under-yard {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .head {
    text-align: center;
    letter-spacing: 3px;
    padding-top: 50px;
    font-size: 2rem;
    font-weight: 700;
    color: #103E58;
    ;
  }
}

@media (min-width: 200px) and (max-width: 400px) {
  .yard-content {
    font-size: 1rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {

  .about {
    height: auto;
  }

  .image-description {
    flex-direction: column-reverse;
    height: auto;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .description-container {
    padding: 20px 40px;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .image-container {
    flex: 1;
    /* margin-right: 20px; */
    padding: 10px 10px;

  }

  .image-container img {
    /* padding: 20px 10px 0 10px; */
    padding: 0;
    height: auto;
    width: 100%;


  }

  .description-container p {
    margin: 0;
    padding: 20px 40px;
    text-align: center;
    font-size: 1.5rem;
  }

  .head {
    text-align: center;
    letter-spacing: 3px;
    padding-top: 50px;
    font-size: 2.5rem;
    font-weight: 700;
    color: #103E58;
    ;
  }

  .under-yard {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .yard-heading {
    font-size: 1.5rem;
  }
}

/* end */

/* team section */

.team-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);

}

.team-section__content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* max-width: 900px; */
  /* margin: 0 auto; */
}

.team-section__about {
  width: 60%;
}

.team-section__about h2 {
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 0px;
  margin: 20px 0;
}

.team-section__about p {

  padding: 20px 10px 40px 10px;
  font-size: 1.5rem;
  line-height: 1.2;
  /* background-color: #e0f6ff41; */
  /* border-radius: 20%; */
}

.team-section__image img {
  padding: 60px 0px 0 50px;
  width: 90%;
  height: 100%;

}


@media screen and (min-width: 250px) and (max-width: 600px) {
  .team-section {
    padding: 20px;
    height: auto;
  }

  .team-section__content {
    flex-direction: column;
  }

  .team-section__about {
    width: 100%;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
  }

  .team-section__about p {
    padding: 20px;
  }

  .team-section__image img {
    padding: 10px 0;
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .team-section {
    padding: 20px;
    height: auto;
  }

  .team-section__content {
    flex-direction: column;
  }

  .team-section__about {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
  }

  .team-section__about p {
    padding: 20px;
  }

  .team-section__image img {
    padding: 10px 0;
    width: 100%;
    height: auto;
  }
}




/* Flipcard Starts */

.flipCard {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgb(246, 211, 211);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/liv\ \(15\).jpeg);
  height: 110vh;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.card-container {
  width: 100%;
  border-radius: 10px;
  margin-top: 15vh;
  margin-bottom: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.flip-card {
  width: 20vw;
  height: 30vw;
  border-radius: 20px;
  perspective: 1000px;
  background-color: transparent;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card:hover {
  cursor: pointer;
}

.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: grid;
  place-items: center;
  background-color: #536976;
  display: grid;
  place-items: center;
  border: 1px solid rgb(146, 142, 142);
  border-radius: 10px;
  object-fit: cover;
  color: white;
}

.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: grid;
  place-items: center;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
  border-radius: 10px;
}

.flip-card-front img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 10px;
}

.flip-card-back h3 {
  color: rgb(198, 128, 36);
}

.FlipcardNumber {
  width: 149px;
  height: 84px;
  color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 85px;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.09em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #103E58;
}

.FlipcardName {
  margin-top: -1rem;
  font-size: 1.9rem;
  margin-bottom: -3rem;
}

.FlipcardText {
  font-size: 1.2rem;
  margin-top: -80px;
  padding: 2rem 1rem;
}

.FlipcardHighlighter {
  background-color: #103E58;
  height: 0.25rem;
  width: 4rem;
}

.services {
  font-size: 3rem;
  padding-top: 10rem;
  font-weight: 700;
  color: rgba(187, 210, 197, 1);

}

.card-back-logo {
  width: 60px;
  /* box-shadow: 2px 2px 4px black; */
}


@media (min-width:600px) and (max-width:1200px) {



  .FlipcardName {
    font-size: 1.3rem;
    margin-top: -5rem;
    margin-bottom: -4rem;
  }

  .FlipcardNumber {
    font-size: 60px;
  }

  .flip-card {
    width: 23vw;
    height: 19rem;
  }

  .FlipcardHighlighter {

    width: 3rem;
  }

  .FlipcardText {
    font-size: 1.2rem;
    padding: 20px;
    padding-top: 4rem;

  }

  .services {
    padding-top: 8rem;
  }

  .card-container {
    margin-top: 10vh;
  }

  .flipCard {
    height: 80vh;
  }
}

@media (min-width:600px) and (max-width:800px) {
  .FlipcardText {
    font-size: 0.7rem;
    padding: 20px;
    padding-top: 3rem;
  }
}

@media (min-width:200px) and (max-width:600px) {
  .FlipcardName {
    font-size: 1.2rem;
    margin-top: -40px;
  }

  .FlipcardNumber {
    font-size: 45px;
  }

  .flip-card {
    width: 90vw;
    height: 30vh;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .FlipcardText {
    font-size: 0.9rem;
    padding: 1rem;
    padding-top: 5rem;
  }

  .FlipcardHighlighter {
    margin-top: 1.5rem;
    width: 3rem;
  }

  .card-container {
    margin-top: 10vh;
  }

  .flipCard {
    height: 160vh;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .flip-card-inner {
    position: relative;
    width: 95%;
    height: 95%;
    transition: 0.6s;
    transform-style: preserve-3d;
  }
}

@media (min-width:600px) and (max-width:750px) {
  .FlipcardName {
    font-size: 1.5rem;
    padding-top: 60px;
  }

  .FlipcardNumber {
    font-size: 50px;
  }

  .flip-card {
    width: 48vw;
    height: 34vh;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .FlipcardText {
    font-size: 1rem;
    padding: 1rem;
    padding-top: 6rem;
  }

  .FlipcardHighlighter {
    margin-top: 3rem;
    width: 3rem;
  }

  .card-container {
    margin-top: 10vh;
  }

  .flipCard {
    height: 110vh;
  }
}



/* Review Section Starts */

.review-card {
  padding-top: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.card-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  font-size: 15rem;
  box-shadow: 4px 4px 18px rgb(163, 162, 162);
}

@media (min-width:600px) and (max-width:1000px) {

  .card-image {
    margin-bottom: 90px;
    width: 200px;
    height: 200px;
    box-shadow: 2px 4px 18px rgb(163, 162, 162);
  }
}

@media (min-width:200px) and (max-width:600px) {

  .card-image {
    margin-bottom: 90px;
    width: 200px;
    height: 200px;
    box-shadow: 2px 4px 18px rgb(163, 162, 162);
  }
}

.card-content {
  padding-left: 2rem;
  margin-left: 1.6rem;
  width: 24rem;
  margin-right: 0.4rem;
  margin-top: 2.4rem;
}

.card-title {
  font-size: 3rem;
  font-weight: bold;
}

.card-job {
  font-size: 1.6rem;
  color: blue;
}

/* .card-text {
  /* Add your custom styles for the card text */


/* Responsive Styles */
@media (max-width: 1000px) {
  .review-card {
    padding-top: 100px;
    flex-direction: column;
  }

  .card-title {
    margin-top: -90px;
    text-align: center;
  }

  .name-under-rev {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  .card-content {
    margin: 1.6rem 0;
    padding-left: 2rem;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .card-title {
    margin-top: -60px;
    font-size: 2rem;
    text-align: center;
  }

  .card-job {
    font-size: 1.2rem;
  }

  /* Add additional responsive styles as needed */
}

.review-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

}

.review-button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

}

.review-button:hover {
  background-color: rgba(89, 221, 238, 0.466);
}


.review-button button {
  color: rgb(175, 175, 176);
  font-size: 4rem;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-button button:hover {
  color: blueviolet;
}

.review-image {
  width: 30rem;
  height: 30rem;
  border-radius: 0.5rem;
}

.review-content {
  margin-left: 1.6rem;
  width: 24rem;
  margin-right: 0.4rem;
  margin-top: 2.4rem;
}

.review-title {
  font-size: 3rem;
  font-weight: bold;
}

.review-job {
  font-size: 1.6rem;
  color: blue;
}

.left {
  margin-left: 30px;
}

.right {
  margin-right: 30px;
}

@media (min-width: 600px) and (max-width: 1000px) {
  .left {
    margin-left: 58px;
    margin-right: -33px;
  }

  .left1 {
    width: 38px;
  }

  .right {
    margin-right: 58px;
    margin-left: -40px;
  }

  .right1 {
    width: 38px;
  }

}

@media (min-width: 200px) and (max-width: 360px) {
  .left {
    margin-top: 320px;
    margin-left: 53px;
    margin-right: -33px;
  }

  .left1 {
    width: 40px;
  }

  .right {
    margin-top: 320px;
    margin-right: 53px;
    margin-left: -33px;
  }

  .right1 {
    width: 40px;
  }

}

@media only screen and (min-width: 200px) and (max-width: 600px) {

  .review-button button {
    font-size: 2rem;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .review-button {
    background-color: transparent;
    padding: 0 20px;
  }

  .review-button:hover {
    background-color: transparent;
    padding: 0 20px;
  }

  .review-section {
    margin-top: 1.4rem;
  }

  .review-button {
    width: 1.5rem;
    height: 1.5rem;
  }

  .review-image {
    width: 20rem;
    height: 20rem;
  }

  .review-content {
    margin-left: 1rem;
    width: 22rem;
    margin-right: 0.2rem;
    margin-top: 1.2rem;
  }

  .review-title {
    font-size: 2.5rem;
    text-align: center;
  }

  .review-job {
    font-size: 1.4rem;
  }
}


/* Review Section Ends */
/* Landing Page Starts */


/* image slider  */
.slider {
  background: linear-gradient(180deg, rgba(83, 105, 118, 0.85) 0%, rgba(187, 210, 197, 0.412604) 51.46%, rgba(41, 46, 73, 0) 100%);
}

.ImageSlider {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: 0;
  margin-bottom: 20px;
  background-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
}

.panel {
  /* background-size: auto 110%; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  border-radius: 50px;
  color: antiquewhite;
  cursor: pointer;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: flex 0.7s ease-in;
  -webkit-transition: all 700ms ease-in;
  width: calc(50% - 20px);
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
}

.panel.active {
  flex: 4;
  border-radius: 40px;
  opacity: 1;
}

.panel.active h3 {
  opacity: 1;
  transition: opacity 0.3s ease-in 0.4s;
  padding-left: 40px;
}

/* Responsive Styles */

@media (max-width: 800px) {
  .ImageSlider {
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    margin-bottom: 20px;

    width: 100%;
  }

  .panel {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
    border-radius: 50px;
    color: antiquewhite;
    cursor: pointer;
    flex: 0.5;
    margin: 10px;
    position: relative;
    transition: flex 0.7s ease-in;
    -webkit-transition: all 700ms ease-in;
    width: 100%;
  }

  .panel.active {
    width: 100%;
    flex: 4;
    border-radius: 50px;
    height: auto;
  }
}

/* loader  */
.load-img {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  background: radial-gradient(rgba(83, 105, 118, 1), rgba(187, 210, 197, 0.4854));
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}

.load-image {
  animation: load 2s ease-in-out;
}

@keyframes load {
  0% {
    scale: 0.1;
    overflow: hidden;
  }

  100% {
    scale: 4;
    overflow: hidden;
  }
}

@media (max-width: 1000px) {
  @keyframes load {
    0% {
      scale: 0.1;
      overflow: hidden;
    }

    100% {
      scale: 1;
      overflow: hidden;
    }
  }
}

/* ---------------------- */
.recent-project {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 3rem;
  text-align: center;
}

@media (max-width: 600px) {
  .recent-project {
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 2rem;
  }

}

.recent-under {
  width: 80px;
  height: 4px;
  background-color: #103E58;
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
}

.name-under-rev {
  margin-top: 5px;
  width: 80px;
  height: 4px;
  background-color: #103E58;
}

.card-text {
  font-family: 'Kalam', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 31px;
}

@media(min-width:600px) and (max-width:1000px) {
  .card-text {
    font-family: 'Kalam', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    padding-right: 20px;
  }
}

@media(min-width:200px) and (max-width:600px) {
  .card-text {
    font-family: 'Kalam', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    padding-right: 20px;
  }
}

/* footer  */
.logo {
  margin-left: 40px;
}

.footer-section {
  margin: 0%;
  overflow-x: hidden;
  padding-top: 90px;
  width: 100%;
  background: #536976;
  height: auto;
}

.foot {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

}

.logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px ;
  width: 300px;
}

.logo-img img{
  width: 100%;
}

.features {
  color: white;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 0.02em;
  background: none;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.underline {
  margin-top: -20px;
  width: 84px;
  height: 4px;
  background-color: #103E58;
}

.linksF {
  margin-top: 15px;
  text-decoration: none; 
  list-style: none;
}

.linkF{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* font-style: normal; */
  text-decoration: none;

  list-style: none;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: white;
  line-height: 10px;
  margin-bottom: 20px;
  transition: all;
  padding-left: 10px;
  cursor: pointer;
  
  
}

.linkF:hover {
  color: #103E58;
  transition-duration: 0.8s;
  scale: 1.01;
}

.office {
  background: none;
  padding-bottom: 30px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 0.02em;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.underline-of {
  width: 84px;
  height: 4px;
  margin-top: -20px;
  background: #103E58;

}

.of-para {
  color: white;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
}

@media (min-width:200px) and (max-width:400px) {
  .of-para {
    color: white;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
  }

}

.underline-sec {
  margin-top: 10px;
  width: 147px;
  height: 0px;
  border: 2px solid white;
}

.number {
  padding-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 13px;
  color: white;
}

.line {
  margin-top: 70px;
  margin-bottom: 10px;
  width: 90%;
  height: 2px;
  background-color: #103E58;
  margin-left: auto;
  margin-right: auto;

}

.copyright {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.mail-id {
  display: flex;
}

.foot-img {
  width: 50px;
  margin-top: 20px;
  margin-left: 10px;
  transition: all;
}

.foot-img:hover {
  transition-duration: 0.5s;
  scale: 1.1;
}

.head-mail {
  padding-top: 15px;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  padding-left: 20px;
}

.underline-third {
  width: 250px;
  height: 1px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feat-sec,
.office-sec,
.cont-sec {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cont-sec {
  padding-top: 100px;
}

@media (min-width:600px) and (max-width:1200px) {
  .features {
    color: white;
    padding-bottom: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 0.02em;
    background: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .office {
    background: none;
    padding-bottom: 30px;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 0.02em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .foot {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width:200px) and (max-width:600px) {
  .features {
    color: white;
    padding-bottom: 30px;
    padding-top: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.02em;
    background: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

  }

  .office {
    background: none;
    padding-bottom: 30px;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.02em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .foot {
    grid-template-columns: repeat(1, 1fr);
  }

  .logo-img {
    justify-content: center;
  }

  .feat-sec,
.office-sec,
.cont-sec {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
}

/* revew  */
.coma1 {
  margin-left: -40px;
  width: 30px;
}

.coma2 {
  width: 30px;
  margin-left: 10px;
  margin-top: -10px;
}

.our-cust {
  text-align: center;
  margin-top: 100px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 3rem;
}

.review-head {
  color: #103E58;
  font-weight: 700;
  font-size: 2.4rem;

}

.review-yard {
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(359.59deg, rgba(41, 46, 73, 0.4) 0.34%, rgba(187, 210, 197, 0.197917) 99.6%, rgba(83, 105, 118, 0) 196.83%);
}

@media (min-width:200px) and (max-width:1000px) {
  .our-cust {
    text-align: center;
    margin-top: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: -40px;
  }

  .coma1 {
    margin-left: -30px;
    width: 20px;
  }

  .coma2 {
    width: 20px;
    margin-left: 6px;
    margin-top: -8px;
  }
}

/* AboutPage Starts */
/* AboutPage Starts */

.aboutPage {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.about0 {
  width: 100%;
  height: auto;
  text-align: center;
  background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
}

.about00 {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(0, 0, 0);
  padding: 0 5%;
}

.aboutHeading {
  font-size: 3.3rem;
  /* box-sizing: border-box; */
  font-weight: 700;
  color: #103E58;
  padding: 8% 3% 2% 3%;
}

.aboutHeading2 {
  font-size: 3.3rem;
  font-weight: 700;
  color: white;
  margin: 3% 3% 3% 3%;
}

.aboutButton {
  margin: 4% 4% 0 4%;
  padding: 1%;
  color: white;
  background-color: #103E58;
  height: 42px;
  /* width: auto; */
  border: 2px#000000 solid;
  border-radius: 3rem;
  cursor: pointer;
}

.aboutCount1 {
  padding: 5%;
  margin: 5% 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #536976;
  font-weight: 700;
}

.counttext{
  letter-spacing: 1px;
}
.Countup {
  font-size: 36px;
}

.count{
  color: white !important;
}

.about1 {
  width: 90%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/team10.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 5% 5%;
  background-attachment: fixed;
}

.abouttext {
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  padding: 0 10%;
}

.about2 {
  width: auto;
  padding: 0% 8% 1% 8%;
  background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
}

.abc {
  background-image: url(./img/abee.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top 50% left 50%;
  /* background-position: bottom bottom; */
}

h3 {
  text-align: center;
  width: 90%;
  height: 2rem;
  /* background-color: rgba(240, 255, 255, 0.201); */
  /* color: #103E58; */
  color: black;
  padding: 4% 2% 0 2%;
  font-family: 'Inria Sans';
  font-style: normal;
  font-weight: 700;
  margin: 2% auto 0 auto;
  font-size: 24px;
  line-height: 36px;
  flex-wrap: wrap;
}

.about3 {
  text-align: center;
  font-size: 18px;
  font-weight: 450;
  line-height: 1.5;
  color: rgb(0, 0, 0);
  
}

.Highlighter {
  background-color: #103E58;
  height: 0.25rem;
  width: 5rem;
  margin: 1% auto 4% auto;
}

@media only screen and (max-width: 800px) {

  .aboutHeading {
    font-size: 2.3rem;
    /* box-sizing: border-box; */
    font-weight: 700;
    color: #103E58;
    padding: 18% 3% 2% 3%;
  }

  .aboutHeading2 {
    font-size: 2.3rem;
    font-weight: 700;
    color: white;
    margin: 3% 3% 3% 3%;
  }

  .about00 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    padding: 0 5%;
  }

  .about3 {
    text-align: center;
    font-size: 16px;
    font-weight: 350;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    padding-bottom: 4%;
  }

  .abouttext {
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    padding: 0 10%;
  }

  .aboutButton {
    margin: 4% 4% 2% 4%;
    padding: 2%;
    color: white;
    background-color: #103E58;
    height: auto;
    border: 2px#000000 solid;
    border-radius: 3rem;
  }

  .about0 {
    width: 100%;
    height: auto;
    text-align: center;
    background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
  }

 

}

@media only screen and (max-width: 600px){
  .about0 {
    width: 100%;
    height: auto;
    text-align: center;
    background: linear-gradient(89.58deg, #BBD2C5 0.33%, rgba(83, 105, 118, 0.511459) 48.84%, rgba(97, 99, 106, 0) 99.62%);
  }

  .aboutCount1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column wrap;
  }

  .count{
    margin: 1.5% 0;
    padding-top: 6%;
  }
  
  .Countup {
    font-size: 26px;
  }

  .about3 {
    text-align: center;
    font-size: 16px;
    font-weight: 450;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    padding-bottom: 6%;
  }
}

 @media only screen and (max-width: 450px){

  .aboutButton {
    margin: 4% 4% 2% 4%;
    padding: 1%;
    color: white;
    background-color: #103E58;
    height: auto;
    border: 2px#000000 solid;
    border-radius: 3rem;
  }

  h3 {
    text-align: center;
    width: 90%;
    height: auto;
    /* background-color: rgba(240, 255, 255, 0.201); */
    color: #103E58;
    padding: 4% 2% 0 2%;
    font-family: 'Inria Sans';
    font-style: normal;
    font-weight: 700;
    margin: 2% auto 0 auto;
    font-size: 24px;
    line-height: 36px;
    flex-wrap: wrap;
    /* margin: 0; */
    padding: 0;
  }

  .Highlighter2 {
    text-align: center;
    width: 90%;
    height: auto;
    /* background-color: rgba(240, 255, 255, 0.201); */
    color: #103E58;
    padding: 4% 2% 0 2%;
    font-family: 'Inria Sans';
    font-style: normal;
    font-weight: 700;
    margin: 2% auto 0 auto;
    font-size: 24px;
    line-height: 36px;
    flex-wrap: wrap;
    /* margin: 0; */
    padding: 0;
  }

  .abouttext {
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    padding: 0 10%;
  }
}


/* AboutPage Ends */

/* AboutPage Ends */



/* contactk */

.backg {
  height: 60vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./img/liv2.jpeg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4 {
  font-size: 7rem;
  color: #e0f6ff;
}

@media (min-width:250px) and (max-width: 900px) {
  h4 {
    font-size: 3rem;
    color: #e0f6ff;
  }
}





.formbox {
  padding: 40px 30px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.243)), url(./img/bed\ \(2\).jpeg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.cardd {
  background-image: linear-gradient(to bottom, #1511117c, #1615157c),url(./img/bed.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #536976;
  height: 87.2vh;
  width: 400px;
}

.textsaboutform {
  padding: 20px 70px;
  font-weight: 400;
  font-size: 1rem;
  color: #e0f6ff;
}

.textCARD {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
  color: white;
}


.textsec {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.form {
  width: 400px;
  padding: 20px;
  background-color: #fefefe;
  /* border-radius: 5px; */
}

h1 {
  text-align: center;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

button.buttonCOn {
  background-color: #536976;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

button.buttonCOn:hover {
  background-color: #103E58;
}

@media screen and (min-width: 250px) and (max-width: 900px) {
  .form {
    width: 100%;
    padding: 40px 20px;
    /* max-width: 400px; */
    /* margin: 0 auto; */
  }

  .textsec {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  

  .formbox {
    padding: 20px 20px;
    margin: 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
  }
  .cardd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #536976;
    height: auto;
    width: auto;
    padding: 50px 20px;
  }

  .textsaboutform {
    padding: 20px 20px;
    font-weight: 400;
    font-size: 1rem;
    color: #e0f6ff;
  }
}




.social {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
align-items: center;
justify-items: center;
  padding: 90px 180px;
}



.cd {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 200px ;
  width: 200px;

}

.cd:hover{
  /* box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.313); */
  border-radius: 10%;
}

.cd img {
  width: 100px;
  height: 100px;
}



/* Optional styling for hover effect */

.cd img:hover {
  transform: scale(1.5);
  transition: all 0.8s ease-out;
  
}




.getText {
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  color: #000000c1;
  margin: 0;
  padding: 90px 0px 10px 0;
}


@media screen and (min-width: 250px) and (max-width: 900px) {
  .social {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 40px;
  }

  .getText {
    text-align: center;
    font-size: 1.5rem;
    color: #000000db;
    margin: 0;
    padding: 20px 0px;
  }

  .cd {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: auto ;
    width: auto;
  
  }

  .cd .cdtext{
    font-size: .8rem;
    text-align: center;
    text-transform: uppercase;
  }

  .cd:hover{
    box-shadow: none;
    border-radius: 0;
  }


  .cd img {
    width: 70px;
    height: 70px;
  }
}

/* @media screen and (min-width: 800px)  {
  .social {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 50px 40px;
  }

  .getText {
    text-align: center;
    font-size: 1.5rem;
    color: #000000db;
    margin: 0;
    padding: 20px 0px;
  }

  .cd {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: auto ;
    width: auto;
  
  }

  .cd .cdtext{
    font-size: .8rem;
    text-align: center;
    text-transform: uppercase;
  }

  .cd:hover{
    box-shadow: none;
    border-radius: 0;
  }


  .cd img {
    width: 70px;
    height: 70px;
  }
} */



